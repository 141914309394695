<template>
	<fragment></fragment>
</template>

<script>
import _ from 'lodash';
import ConcurrentAccess from '@/components/login/ConcurrentAccessModal.vue';
import { CONCURRENT_ACCESS_MODAL_STYLE } from '@/utils/constants';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';

export default {
	name: 'LinkAccess',
	created() {
		const path = this.$route.path;
		const isInvite = path.includes('/invite');

		const code = this.$route.query.code;
		if (_.isEmpty(code)) {
			this.$router.push('/login');
			return;
		}
		if (isInvite) {
			this.inviteMember(code);
			return;
		}
		this.ssoDaouoffice(code);
	},
	mixins: [mixinsErrCodeObserver],
	methods: {
		inviteMember(code) {
			this.$store.commit('SET_INVITE_CODE', code);
			this.$router.replace('/login');
		},
		ssoDaouoffice(code) {
			this.$store
				.dispatch('ssoDaouOffice', code)
				.then((res) => {
					const token = sessionStorage.getItem('authToken');
					if (!token) {
						const errorCode = res.data;
						this.ssoFail(code, errorCode);
						return;
					}
					this.$router.push('/home');
				})
				.catch((e) => {
					const data = e.data;
					if (e.status == '403' && data.errorCode === 'GW_ERR001') {
						const token = e.headers.authorization;
						this.$modal.show(
							ConcurrentAccess,
							{ loginId: this.email, token: token },
							Object.assign({}, CONCURRENT_ACCESS_MODAL_STYLE, { name: ConcurrentAccess.name, clickToClose: false }),
						);
						return;
					}
				});
		},
		ssoFail(code, errorCode = '') {
			this.$router.push({ name: 'Login', query: { daouofficeCode: code, errorCode } });
		},
	},
};
</script>
